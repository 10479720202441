import { format } from 'date-fns';
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type dateFormatterContext = (
  date: any,
  dateFormat: dateFormats,
  timezone?: string | undefined
) => string | null;

const initialDataFormatter = (date: any, dateFormat: dateFormats, timezone?: string | undefined) =>
  null;

const DateFromatterSetterContext = createContext<Dispatch<SetStateAction<any>> | null>(null);
const DateFormatterContext = createContext<dateFormatterContext>(initialDataFormatter);

type dateFormats = 'FULL_DATETIME';

const standardPattern: Record<dateFormats, string> = {
  FULL_DATETIME: 'EEE MMM dd yyyy, hh:mm:ss a',
};

function DateFormatWrapper({ children }: any) {
  const [customDateFormats, setCustomDateFormats] = useState<any>({});

  const formatDate = (date: any, dateFormat: dateFormats, timezone?: string) => {
    let formatTo = standardPattern[dateFormat];

    const customFormat = customDateFormats && customDateFormats[dateFormat];

    if (customFormat) {
      formatTo = customFormat;
    }

    try {
      return formatTo ? format(new Date(date), formatTo) : null;
    } catch (error) {
      console.error(`Failed to format date: ${error.message}`);
      return null;
    }
  };

  return (
    <DateFromatterSetterContext.Provider value={setCustomDateFormats}>
      <DateFormatterContext.Provider value={formatDate}>{children}</DateFormatterContext.Provider>
    </DateFromatterSetterContext.Provider>
  );
}

export const useDateFormatter = () => {
  return useContext(DateFormatterContext);
};

export const useSetDateFormatter = () => {
  return useContext(DateFromatterSetterContext);
};

export default DateFormatWrapper;
